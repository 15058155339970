@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 445px;
}

.block {
  margin-top: 50px;
  margin-left: 11.11%;
  margin-right: 15.28%;

  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 61px;
  color: #ffffff;
}

.title {
}

.text {
  li {
    margin-bottom: 20px;
    line-height: 62px;

    &::marker {
      color: #d1132f;
    }
  }
}
