@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;
  position: relative;
}

.slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;

  .slide {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    width: 1080px !important;
    height: 1920px !important;

    img {
      max-width: 100%;
      align-self: center;
    }

    div {
      color: #fff;
      font-style: normal;
      font-weight: 300;
      font-size: 52px;
      line-height: 62px;
      margin-top: 25px;
      margin-left: 11.11%;
      margin-right: 11.11%;
    }
  }
}

.sliderDots {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 46px;
  width: 100%;

  > div {
    width: 45px;
    height: 45px;
    margin: 0 13px;
    border: 8px solid #fff;
    border-radius: 25px;

    &.activeDot {
      background: #fff;
    }
  }
}

.title {
  position: relative;
  display: block;
  height: 52px;
  margin-top: -50px;

  .titleNormal {
    font-family: 'Metric';
    font-style: normal;
    font-weight: 500;
    font-size: 83px;
    line-height: 100px;
    // text-transform: uppercase;
    color: #323333;
  }

  .titleFresh {
    font-family: Custom Fresh;
    font-style: normal;
    font-weight: normal;
    font-size: 122px;
    line-height: 147px;
    color: #ee405a;
  }
}
