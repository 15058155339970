@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.image {
  width: 100%;
  margin-top: 445px;
}

.desc {
  margin-left: 11.11%;
  margin-right: 15.28%;
  margin-top: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;

  .list {
    margin: 0;
    padding: 0;
    padding-left: 25px;

    li {
      margin-bottom: 20px;
    }
  }

  span {
    color: #D1132F;
  }

  a {
    color: #fff;

    span {
      font-size: 21px;
      position: relative;
      top: 2px;
    }

    &:hover {
      color: #fff;
    }
  }
}