@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.brandIdea {
  width: 100%;
  margin-top: 445px;
}

.text {
  > b {
    font-weight: 600;
  }
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 61px;
  color: #ffffff;
  margin-left: 11.11%;
  margin-right: 15.28%;
  margin-top: 61px;
}
