@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.video {
  width: 100%;
  margin-top: 445px;
}
