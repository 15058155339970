@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;

  .blocks {
    display: flex;
    flex-direction: column;

    > div:nth-child(1) .block {
      background: url(sources/images/our-brand-menu.jpg);
      width: 100%;
      height: 640px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #3d2727;
        opacity: 0.5;
      }
    }

    > div:nth-child(2) .block {
      background: url(sources/images/girl-menu.jpg);
      width: 100%;
      height: 640px;
    }

    > div:nth-child(3) .block {
      background: url(sources/images/inovations-menu.jpg);
      width: 100%;
      height: 640px;
    }

    .block {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 83px;
        line-height: normal;
        text-align: center;
        border: 10px solid #ffffff;
        max-width: 872px;
        width: 872px;
        padding: 30px;
        position: relative;
        z-index: 1;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
}
