@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.whoWeAre {
  width: 100%;
  margin-top: 445px;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 62px;
  color: #FFFFFF;
  margin-left: 11.11%;
  margin-right: 15.28%;
  margin-top: 40px;
}

.button {
  font-weight: 500;
  font-size: 52px;
  line-height: 92px;
  text-align: center;
  color: #FFFFFF;
  border: 8px solid #FFFFFF;
  box-sizing: border-box;
  margin-left: 9.63%;
  margin-right: 9.63%;
  margin-top: 46px;
  display: block;
  padding: 33px 0;
}
