
.group {
  overflow: hidden;
  width: 100%;
  height: 100%;

  > div,
  > a {
    width: 1080px;
    height: 1920px;
    position: relative;
  }

  &.forward {
    .enter {
      animation: forwardEnter 0.5s;

      &:after {
        z-index: 100000000;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent;
        display: block;
        content: '';
      }
    }
    .exit {
      animation: forwardExit 0.5s;

      &:after {
        z-index: 100000000;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent;
        display: block;
        content: '';
      }
    }
  }

  &.ahead {
    .enter {
      animation: aheadEnter 0.5s;

      &:after {
        z-index: 100000000;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent;
        display: block;
        content: '';
      }
    }
    .exit {
      animation: aheadExit 0.5s;

      &:after {
        z-index: 100000000;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent;
        display: block;
        content: '';
      }
    }
  }
}

@keyframes forwardEnter {
  from {
    transform: translate3d(100%, 0, 0); 
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes forwardExit {
  from {
    transform: translate3d(0, -100%, 0); 
  }
  to {
    transform: translate3d(-100%, -100%, 0);
  }
}

@keyframes aheadEnter {
  from {
    transform: translate3d(-100%, 0, 0); 
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes aheadExit {
  from {
    transform: translate3d(0, -100%, 0); 
  }
  to {
    transform: translate3d(100%, -100%, 0);
  }
}