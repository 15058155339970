@import 'sources/styles/variables.scss';

.container {
  background: #000;
  color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;
  position: relative;
}

.aeg {
  position: absolute;
  left: 360px;
  top: 325px;
}

.crossTitle {
  position: absolute;
  left: 11.11%;
  right: 11.11%;
  top: 9.2%;
  bottom: 82.97%;
  font-style: normal;
  font-weight: 300;
  font-size: 52px;
  line-height: 62px;
  text-align: center;
  color: #ffffff;
}

.slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;

  .slide {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    width: 1080px !important;
    height: 1920px !important;
    position: relative;

    &.slide1 {
      background: url(sources/images/our-brand.jpg);
      background-position-x: 0%;
      background-size: cover;
      animation: slide linear 60s infinite;
    }

    &.slide2 {
      background: url(sources/images/home-2.jpg);
      background-position-x: 0%;
      background-size: cover;
      animation: slide linear 25s infinite;
    }

    &.slide3 {
      background: url(sources/images/inovations.jpg);
      background-position-x: 0%;
      background-size: cover;
      animation: slide linear 45s infinite;
    }

    .image {
      width: 100%;
      height: 1920px;
    }

    .title {
      position: absolute;
      top: 800px;
      text-align: center;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 83px;
      line-height: 99px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
      padding: 0 50px;
    }
  }
}

.sliderDots {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 46px;
  width: 100%;
  z-index: 10;

  > div {
    width: 45px;
    height: 45px;
    margin: 0 13px;
    border: 8px solid #fff;
    border-radius: 25px;

    &.activeDot {
      background: #fff;
    }
  }
}

.trigger {
  width: 164px;
  height: 164px;
  border-radius: 82px;
  background: rgba(0, 0, 0, 0.6);
  align-self: center;
  margin-top: 1183px;
  position: relative;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  left: 442px;
  position: absolute;

  .hand {
    width: 302px;
    height: 379px;
    background: url(sources/images/hand-icon.png);
    position: absolute;
    top: 62px;
    left: -53px;
    animation: scalePulse 2s infinite;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes scalePulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 0%;
  }
}
