@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.desc {
  position: absolute;
  left: 11.11%;
  right: 13.28%;
  top: 56.61%;
  bottom: 7.92%;
  font-family: Metric;
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 65px;
  color: #fff;

  a {
    display: block;
    color: #ee405a;
    margin-top: 60px;
    margin-bottom: 125px;
    font-weight: 300;

    &:hover {
      color: #ee405a;
    }

    span {
      font-size: 31px;
      position: relative;
    }
  }

  strong {
    font-weight: 600;
  }
}

.aeg {
  align-self: center;
  margin-top: 690px;
}
