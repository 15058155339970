@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.image {
  width: 100%;
  margin-top: 445px;
}

.desc {
  margin-left: 11.11%;
  margin-right: 15.28%;
  margin-top: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 60px;
  color: #ffffff;

  span {
    color: #d1132f;
  }

  a {
    color: #d1132f;

    span {
      font-size: 31px;
      position: relative;
      top: 2px;
    }

    &:hover {
      color: #fff;
    }
  }
}
