@import 'sources/styles/variables.scss';

.container {
  background: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.text {
  margin-top: 445px;
  margin-left: 11.11%;
  margin-right: 15.28%;

  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 61px;
  color: #ffffff;

  b {
    font-weight: 500;
  }
}
